import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

import { Card, Row, Col, Button } from "react-bootstrap";

import styles from "./story-preview.module.css";

export default ({ story }) => (
  <>
    <Card className={`${styles.storyCard} w-100 mb-1`}>
      <Card.Body>
        <Row>
          {/* <Col sm={2} className={styles.countryLogo}>
            <Img alt="" fixed={story.countryOfOrigin.flag.fixed} />
          </Col> */}
          <Col sm={12}>
            <Card.Title>
              <Link style={{ boxShadow: `none` }} to={`/stories/${story.id}`}>
                <h3>{story.title} </h3>
              </Link>
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              <Img alt="" fixed={story.countryOfOrigin.flag.fixed} /> Posted on{" "}
              <strong>{story.publishDate}</strong>
            </Card.Subtitle>
            <div
              dangerouslySetInnerHTML={{
                __html: story.shortBio.childMarkdownRemark.html
              }}
            ></div>
            <Button
              className={styles.applyButton}
              title={`Apply for ${story.title} at ${story.countryOfOrigin.name}`}
              to={`/stories/${story.id}`}
              target="_blank"
            >
              read story
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </>
);
