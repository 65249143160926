import React from "react";
import { Link } from "gatsby";
import styles from "./footer.module.css";

export default () => (
  <footer className="footer mt-auto py-3">
    <ul className={styles.footer}>
      <li className={styles.navigationItem}>
        <a href="/" alt="Job Board" title="Job Board">
          &nbsp; Home
        </a>
      </li>
      <li className={styles.navigationItem}>
        <Link
          to="/s/terms-and-conditions"
          alt="Terms & Conditions"
          title="Terms & Conditions"
        >
          Terms & Conditions
        </Link>
      </li>
      <li className={styles.navigationItem}>
        <Link to="/s/imprint" alt="Imprint" title="Imprint">
          Imprint
        </Link>
      </li>
      <li className={styles.navigationItem} alt="Contact Us" title="Contact Us">
        <a href="mailto:bythefireside@gmail.com?subject=RE: by the fires die">
          Contact Us
        </a>
      </li>
    </ul>
    <p className={styles.copyright}>
      Powered by{" "}
      <a
        href="https://kloud9.studio"
        alt="Kloud 9 Studios"
        title="Kloud 9 Studios"
        target="_blank"
        rel="noopener noreferrer"
      >
        Kloud 9 Studios
      </a>
    </p>
  </footer>
);
